var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tui tuim ui card" },
    [
      _c("div", { staticClass: "tui tuim ui header" }, [
        _c("h2", {
          staticClass: "card-title",
          domProps: { textContent: _vm._s(_vm.title) }
        })
      ]),
      _c("t-placeholder", { attrs: { lines: _vm.placeholder } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }